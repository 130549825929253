var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-container" },
    [
      _c(
        "div",
        { staticClass: "auth-container__language-dropdown" },
        [
          _c(
            "v-menu",
            {
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "lang-text gotham-medium-font font-14",
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v("English "),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v(" mdi-menu-down "),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [_c("v-list-item-title", [_vm._v("English")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "auth-container__layout-row d-flex align-stretch" },
        [
          _c(
            "v-col",
            { staticClass: "left-column", attrs: { cols: "6", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "left-column__logo" },
                [
                  _c("v-img", {
                    staticClass: "powered-by-logo",
                    attrs: {
                      width: "42%",
                      src: require("../../assets/images/poweredby-light.svg"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "left-column__links d-flex justify-space-between",
                },
                [
                  _c("p", { staticClass: "font-16 gotham-light-font" }, [
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: {
                          href:
                            "https://www.kochava.com/legal/license-service-agreement",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Terms of Service")]
                    ),
                  ]),
                  _c("p", { staticClass: "font-16 gotham-light-font" }, [
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: {
                          href:
                            "https://www.searchadsmaven.com/website-visitor-privacy-policy",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Privacy Policy")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "right-column d-flex justify-center align-center",
              attrs: { cols: "6", xs: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "right-column__form-container" },
                [_c("router-view")],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }